import { useEffect, useMemo } from 'react'
import { useRecoilRefresher_UNSTABLE, useSetRecoilState } from 'recoil'
import { useCookies } from 'react-cookie'
import { authStateCommon, resetState } from '@pig-common/recoils'
import {
  requestVerfiyCfid,
  VERIFY_CFID_STATUS,
} from '@pig-common/services/verifyCfid'
import { COOKIES_KEY } from '@pig-common/utils/cookies'
import useRevokeToken from './useRevokeToken'

const protectedCookies = ['chat_timetoken_log', 'annoucement_readed']

export default function useLogout() {
  const resetAllState = useSetRecoilState(resetState.resetAll)
  const updateIsAuthenticated = useRecoilRefresher_UNSTABLE(
    authStateCommon.isAuthenState,
  )
  const [cookies, , removeCookie] = useCookies()
  const {
    mutate: revokeToken,
    isSuccess: isRevokeTokenSuccess,
    isError: isRevokeTokenFailed,
  } = useRevokeToken()
  const cfid = cookies?.[COOKIES_KEY.CFID]
  const uid = cookies?.[COOKIES_KEY.UID]
  const isAuthenticated = useMemo(() => {
    return requestVerfiyCfid({
      cfid: { value: cfid },
      uid: { value: uid },
    })
  }, [cfid, uid])
  const handleLogOut = () => {
    revokeToken()
    removeCookie(COOKIES_KEY.CHAT_CW_CONV)
    removeCookie(COOKIES_KEY.CHAT_CW_SESSION)
    removeCookie(COOKIES_KEY.CFID)
    removeCookie(COOKIES_KEY.UID)
    removeCookie(COOKIES_KEY.CUSTOMER_CODE)
  }

  useEffect(() => {
    if (isAuthenticated.Status === VERIFY_CFID_STATUS.EXPIRED) {
      handleLogOut()
    }
  }, [isAuthenticated])

  useEffect(() => {
    // handleLogout after revoke token
    if (isRevokeTokenSuccess || isRevokeTokenFailed) {
      const allCookies = Object.keys(cookies)
      allCookies.forEach((cookie) => {
        if (!protectedCookies.includes(cookie)) {
          removeCookie(cookie, { path: '/' })
        }
      })
      localStorage.clear()
      resetAllState()
      updateIsAuthenticated()
      window.location.href = window.location.origin
    }
  }, [isRevokeTokenSuccess, isRevokeTokenFailed])

  return { logout: handleLogOut }
}
